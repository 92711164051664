export class SlopeAndAspect {

    private _heights: number[];
    private _slopes: number[];
    private _aspects: number[];
    private _rows: number;
    private _columns: number;
    private _nullValue: number = Number.MAX_VALUE;
    private _arraySize: number = 0;
    private _heightKernel: number[] = new Array(0,0,0, 0,0,0, 0,0,0);

    private _outputVectorLength: boolean = false;
    private _outputUphillAngles: boolean = false;
    private _outputGeographicAngles: boolean = false;

    private _pi: number = Math.PI;

    constructor(heights: number[], rows: number, columns: number, outputVectorLength: boolean, outputUphillAngles: boolean, outputGeographicAngles: boolean) {
        this._heights = heights;
        this._rows = rows;
        this._columns = columns;

        this._outputVectorLength = outputVectorLength;
        this._outputUphillAngles = outputUphillAngles;
        this._outputGeographicAngles = outputGeographicAngles;

        this._arraySize = rows * columns;
        this._slopes = new Array(this._arraySize);
        this._aspects = new Array(this._arraySize);
    }

    public Calculate()  {
        //init array to all null values
        this._slopes = new Array(this._arraySize);
        this._aspects = new Array(this._arraySize);
        for (let index = 0; index < this._heights.length; index++) {
            this._slopes[index] = this._nullValue;            
            this._aspects[index] = this._nullValue;            
        }

        let col: number = 0;
        let row: number = 0;
        for (let index = 0; index < this._heights.length; index++) {

            col = index % this._columns;
            row = Math.floor(index / this._columns);
          
            let fx, fy;
            let nx, ny;
            let x1, x2, y1, y2;
            let kCol, kRow;

            let cellValue = this._heights[index];
            if (cellValue !== this._nullValue)
            {
                for (let j = -1; j <= 1; j++)
                {
                    for (let i = -1; i <= 1; i++)
                    {
                        kCol = col + i;
                        kRow = row + j;

                        if ((kCol >= 0) && (kCol < this._columns) && (kRow >= 0) && (kRow < this._rows))
                        {
                            let value = this.getLayerValue(kCol, kRow,this._heights);
                            this.setKernelValue(i + 1, j + 1, (value !== this._nullValue) ? value : this._nullValue);
                        }
                        else
                        {
                            this.setKernelValue(i + 1, j + 1, this._nullValue);
                        }
                    }

                    //#region X direction
                    fx = 0;
                    nx = 0;

                    x1 = this.getKernelValue(0, 0) === this._nullValue ?  this.getKernelValue(1, 0) : this.getKernelValue(0, 0);
                    x2 = this.getKernelValue(2, 0) === this._nullValue ?  this.getKernelValue(1, 0) : this.getKernelValue(2, 0);
                    if ((x1 !== null) && (x2 !== this._nullValue))
                    {
                        fx += x2 - x1;
                        nx++;
                    }

                    x1 = this.getKernelValue(0, 1) === this._nullValue ?  this.getKernelValue(1, 1) : this.getKernelValue(0, 1);
                    x2 = this.getKernelValue(2, 1) === this._nullValue ?  this.getKernelValue(1, 1) : this.getKernelValue(2, 1);
                    if ((x1 !== this._nullValue) && (x2 !== this._nullValue))
                    {
                        fx += 2 * (x2 - x1);
                        nx++; nx++;
                    }

                    x1 = this.getKernelValue(0, 2) === this._nullValue ?  this.getKernelValue(1, 2) : this.getKernelValue(0, 2);
                    x2 = this.getKernelValue(2, 2) === this._nullValue ?  this.getKernelValue(1, 2) : this.getKernelValue(2, 2);
                    if ((x1 !== this._nullValue) && (x2 !== this._nullValue))
                    {
                        fx += (x2 - x1);
                        nx++;
                    }
                    fx = fx / nx;
                    //#endregion

                    //#region Y direction
                    fy = 0;
                    ny = 0;

                    y1 = this.getKernelValue(0, 0) === this._nullValue ?  this.getKernelValue(0, 1) : this.getKernelValue(0, 0);
                    y2 = this.getKernelValue(0, 2) === this._nullValue ?  this.getKernelValue(0, 1) : this.getKernelValue(0, 2);
                    if ((y1 !== this._nullValue) && (y2 !== this._nullValue))
                    {
                        fy += (y2 - y1);
                        ny++;
                    }

                    y1 = this.getKernelValue(1, 0) === this._nullValue ?  this.getKernelValue(1, 1) : this.getKernelValue(1, 0);
                    y2 = this.getKernelValue(1, 2) === this._nullValue ?  this.getKernelValue(1, 1) : this.getKernelValue(1, 2);
                    if ((y1 !== this._nullValue) && (y2 !== this._nullValue))
                    {
                        fy += 2 * (y2 - y1);
                        ny++; ny++;
                    }

                    y1 = this.getKernelValue(2, 0) === this._nullValue ?  this.getKernelValue(2, 1) : this.getKernelValue(2, 0);
                    y2 = this.getKernelValue(2, 2) === this._nullValue ?  this.getKernelValue(2, 1) : this.getKernelValue(2, 2);
                    if ((y1 !== this._nullValue) && (y2 !== this._nullValue))
                    {
                        fy += (y2 - y1);
                        ny++;
                    }
                    fy = fy / ny;
                    //#endregion

                    let vectorLength = Math.sqrt(fx * fx + fy * fy);
					if (this._outputVectorLength)
					{
						this.setLayerValue(col, row, this._slopes, 50 * vectorLength); // Scale to per cent slope per pixel.
					}
					else
					{
                        this.setLayerValue(col, row, this._slopes, Math.atan(vectorLength));
					}

                    
                    let dir: number = 0;
                    if (this._outputGeographicAngles)
                    {
                        dir = this.toDegrees(Math.atan2(-fy, fx)); // Inverting Y offset as grid's rows are upside/down to north/south
                        if (!this._outputUphillAngles)
                        {
                            dir += 180; // We want downhill direction = opposite of max uphill
                        }
                        dir = 90 - dir; // From geometric to geographic angle
                        if (dir < -180)
                        {
                            dir += 360;
                        }

                        if (dir > 180)
                        {
                            dir -= 360;
                        }
                    }
                    else
                    {
                        dir = this.toDegrees(Math.atan2(fy, fx));
                        if (!this._outputUphillAngles)
                        {
                            dir += 180; // We want downhill direction = opposite of max uphill
                        }
                        if (dir < 0)
                        {
                            dir += 360;
                        }
                    }

                    this.setLayerValue(col, row, this._aspects, dir);
                }
            }
        }
    }

    public GetSlopeLayer():number[] {
        return this._slopes;
    }
    public GetAspectLayer():number[] {
        return this._aspects;
    }


    private setKernelValue(x:number, y:number, val:number){
        const i = y * 3 + x;
        this._heightKernel[i] = val;
    }
    private getKernelValue(x:number, y:number): number {
        const i = y * 3 + x;
        return this._heightKernel[i];
    }

    private setLayerValue(x:number, y:number, layer:number[], val:number){
        const i = y * this._columns + x;
        layer[i] = val;
    }
    private getLayerValue(x:number, y:number, layer:number[]): number {
        const i = y * this._columns + x;
        return layer[i];
    }

    private toDegrees(radians: number){
        
        return radians * (180.0/this._pi);
      
    }
}

