export class DataStatistics {
    public Count: number = 0;
    public NullCount: number = 0;
    public Sum: number = 0;
    public SumOfSquares: number = 0;
    public Minimum: number = Number.MAX_VALUE;
    public Maximum: number = Number.MIN_VALUE;

    private _nullValue = Number.MAX_VALUE;

    constructor(array: number[], nullValue: number) {
        this._nullValue = nullValue;

        if (array !== null) {

            for (var i = 0; i < array.length; i++) {
                var value = array[i];

                if (value === this._nullValue) {
                    this.NullCount++;
                    continue;
                }

                if (this.Count === 0) {
                    this.Maximum = value;
                    this.Minimum = value;
                }
                else {
                    if (value > this.Maximum) {
                        this.Maximum = value;
                    }
                    else if (value < this.Minimum) {
                        this.Minimum = value;
                    }
                }
                this.Count++;
                this.Sum += value;
                this.SumOfSquares += Math.pow(value, 2);
            }
        }
    }

    public StandardDeviation = (): number => {
        return this.Count === 0 ? 0 : Math.sqrt(Math.abs(this.Count * this.SumOfSquares - Math.pow(this.Sum, 2))) / this.Count;
    }

    // StandardDeviation / Mean
    public CoefficientOfVariance = (): number => {
        return this.StandardDeviation() / Math.abs(this.Mean());
    };


    // CoefficientOfVariance * 100
    public PercentVariance = (): number => {
        return this.CoefficientOfVariance() * 100.0;
    };

    public Mean = (): number => {
        return this.Sum / this.Count;
    };

    public Range = (): number => {
        return this.Maximum - this.Minimum;
    };

    public AddValue = (value: number): void => {

        if (value === this._nullValue) {
            this.NullCount++;
            return;
        }

        if (DataStatistics.IsNumber(value) === false) {
            return; // TODO throw some sort of error
        }
        if (this.Count === 0) {
            this.Maximum = value;
            this.Minimum = value;
        }
        else {
            if (value > this.Maximum) {
                this.Maximum = value;
            }
            else if (value < this.Minimum) {
                this.Minimum = value;
            }
        }
        this.Count++;
        this.Sum += value;
        this.SumOfSquares += Math.pow(value, 2);
    };

    public static IsNumber(n: any): boolean {
        //from : http://stackoverflow.com/questions/8525899/how-to-check-if-a-javascript-number-is-a-real-valid-number
        return typeof n === "number" && !isNaN(n - n);
    }

}