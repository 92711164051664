import { Perlin } from './perlin.ts';
import { Colors } from './colors.ts';
import { SlopeAndAspect } from './calcs.ts';
import { DataStatistics } from './DataStatistics.ts';




let _oldMapWidth = 0;
let _oldMapHeight = 0;

function scheduleUpdateMap(){

     setTimeout(() => {
        updateMap();
     }, 500); 
}

function createTextImageData(width:number, height:number) : ImageData | null
{

    var canvas = document.createElement("canvas");
    canvas.width  = width;
    canvas.height = height;

    if (canvas !== null){
        const ctx = canvas.getContext("2d");
        if (ctx != null){
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, width, height);

            ctx.font =  "bold " + width/4 + "px sans-serif";    
            ctx.fillStyle = "black";
            ctx.textBaseline = "middle";
            ctx.textAlign = "center";
            // ctx.shadowColor="black";
            // ctx.shadowBlur=1;
            // ctx.imageSmoothingEnabled= false;
            ctx.fillText("voneum", width/2, height/2);

            const imageData = ctx.getImageData(0, 0, width, height);

            return imageData;
        }
    }

    return null;
}

function updateMap()
{
    //console.log(divTr.clientWidth + " " + divTr.clientHeight + ", " + divTr.offsetWidth + " " + divTr.offsetHeight);
    
    if (Math.abs(divTr.clientWidth - _oldMapWidth) / divTr.clientWidth < 0.1
            && Math.abs(divTr.clientHeight - _oldMapHeight) / divTr.clientHeight < 0.1)
    {
        console.log("return");
        return;
    }
    
    //console.log("draw");

    const colorTable = Colors.GetElevationColorArray();

    _oldMapWidth = divTr.clientWidth;
    _oldMapHeight = divTr.clientHeight;

    const rows: number = Math.floor(divTr.clientHeight * 2 / 4);
    const columns: number = Math.floor(divTr.clientWidth * 2 / 4);
    const heights: number[] = new Array(rows * columns);

    _canvas.width  = columns;
    _canvas.height = rows;

    if (_canvas !== null){
        const ctx = _canvas.getContext("2d");
        if (ctx != null){
            //ctx.fillStyle = "blue";
            ctx.fillRect(0, 0, _canvas.width, _canvas.height);

            const image = ctx.createImageData(_canvas.width, _canvas.height);
            const data = image.data;
            const seed = Math.random();
            const noise = new Perlin(seed);

            // let min = Number.MAX_VALUE;
            // let max = Number.MIN_VALUE



            for (let x = 0; x < columns; x++) {
                for (let y = 0; y < rows; y++) {
                    
                    const i = y * columns + x;
                    const value = Math.floor((noise.simplex2(x / 200, y / 200) + 1 ) / 2 * 255);
                    heights[i] = value;
                }
            }


            const textImageData = createTextImageData(columns, rows);
            if (textImageData != null) {
                for (let x = 0; x < columns; x++) {
                    for (let y = 0; y < rows; y++) {
                        
                        const i = y * columns + x;
                        const cell=  i * 4;
                        if (textImageData.data[cell] < 128){
                            // if (textImageData.data[cell] > 0){
                            //     heights[i] =  (textImageData.data[cell] / 255) * heights[i];
                            // }
                            // else
                            // {
                                heights[i] = 0;
                            // }
                        }
                    }
                }
            }


            const slopeAndAspect = new SlopeAndAspect(heights, rows, columns, true, false, true);
            slopeAndAspect.Calculate();

            const aspectLayer = slopeAndAspect.GetAspectLayer();
            const dataStats = new DataStatistics(aspectLayer, Number.MAX_VALUE);
            const shadeMin = 0;
            const shadeMax = Math.max(Math.abs(dataStats.Minimum),Math.abs(dataStats.Maximum));            
            const dataRange  = shadeMax - shadeMin; 

            for (let x = 0; x < columns; x++) {
                for (let y = 0; y < rows; y++) {

                    const i = y * columns + x;
                    
                    // All noise functions return values in the range of -1 to 1.
                
                    // noise.simplex2 and noise.perlin2 for 2d noise
                    //const value = Math.floor((noise.simplex2(x / 100, y / 100) + 1 ) / 2 * 255);
                    // if (min > value) { 
                    //     min = value ;
                    //     console.log("min = " + min);
                    // }
                    // if (max < value) { 
                    //     max = value ;
                    //     console.log("max = " + max);
                    // }

                    //console.log("value = " + value);
                    // ... or noise.simplex3 and noise.perlin3:
                    //var value = noise.simplex3(x / 100, y / 100, time);
                
                    const shading = 0.5 + (0.5 - (Math.abs(aspectLayer[i]) - shadeMin) / dataRange / 2);

                    const value = heights[i];
                    const cell = (x + y * _canvas.width) * 4;
                    //data[cell] = data[cell + 1] = data[cell + 2] = value;
                    
                    /* If you only want shading and not colors
                    data[cell] = 255 * shading;
                    data[cell + 1] = 255 * shading;
                    data[cell + 2] = 255 * shading;
                    */

                    data[cell] = colorTable.Red[value] * shading;
                    data[cell + 1] = colorTable.Green[value]* shading;
                    data[cell + 2] = colorTable.Blue[value]* shading;

                    // data[cell] = colorTable.Red[value];
                    // data[cell + 1] = colorTable.Green[value];
                    // data[cell + 2] = colorTable.Blue[value];

                    //data[cell] += Math.max(0, (25 - value) * 8);
                    data[cell + 3] = 255; // alpha.
                  
                }
            }

            ctx.putImageData(image, 0, 0);

            // ctx.font =  "bold " + columns/4 + "px sans-serif";    
            // ctx.fillStyle = "black";
            // ctx.textBaseline = "middle";
            // ctx.textAlign = "center";
            // ctx.fillText("voneum", columns/2, rows/2);
          

        }
    }

}

// function fitToContainer(canvas:HTMLCanvasElement){
//     // canvas.style.width='100%';
//     // canvas.style.height='100%';
//     // canvas.width  = canvas.offsetWidth;
//     // canvas.height = canvas.offsetHeight;
// }

const divTr = document.getElementById("divTopRight") as HTMLDivElement;   
const _canvas = document.getElementById("canv") as HTMLCanvasElement;
console.log(divTr.clientWidth + " " + divTr.clientHeight);

if (divTr !== null){
    console.log(divTr.clientWidth + " " + divTr.clientHeight);
    new ResizeObserver(scheduleUpdateMap).observe(divTr);

}

updateMap();